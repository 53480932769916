import Footer from "./Footer";
import Navbar from "./Navbar";

const ManufacturingFacilites = () => {
  const getImages = () => {
    let content = [];
    for (let i = 1; i <= 4; i++) {
      if (i != null) {
        content.push(
          <div className="col-md-6 col-sm-6 col-xs-12 padd">
            <div className="thumbnail thumbnail3">
              <img
                src={"manufacturing-facilities/" + i + ".jpeg"}
                alt="..."
                className="team-img"
                width="auto"
              />
            </div>
          </div>
        );
      }
    }
    return content;
  };

  return (
    <>
      <section id="contact-banner" className="banner">
        <div className="contact-bg-color">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="banner-logo text-center">
                  <h1 className="white">MANUFACTURING FACILITY</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-padding">
        <div className="container">
          <div className="row">
            {getImages()}
            <div className="col-md-12 col-sm-12 col-xs-12 padd">
            <div className="thumbnail thumbnail3">
              <img
                src={"manufacturing-facilities/" + 5 + ".jpeg"}
                alt="..."
                className="team-img"
                width="auto"
              />
            </div>
          </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ManufacturingFacilites;
