import "./css/capsule.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Capsule = () => {
  return (
    <>
      <section id="contact-banner" className="banner">
        <div className="contact-bg-color">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="banner-logo text-center">
                  <h1 className="white">CAPSULES</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h3 className="my-background">Product List</h3>
              </div>
              <table className="table table-bordered" id="my_table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "8%" }}>
                      S.No
                    </th>
                    <th scope="col" style={{ width: "70%" }}>
                      Composition
                    </th>
                    <th scope="col">Packing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      Antioxidants,Ginseng Multi-vitamin & Multimeral capsules
                    </td>
                    <td>20X15</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Antioxidants,Multi-vitamin & Multimeral capsules</td>
                    <td>20X15</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>B-complex Capsules</td>
                    <td>20X15</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Carbonyl Iron 50mg, folic Acid 0.5mg & Zinc 61.8mg eq. to
                      zinc 22.5 Capsules
                    </td>
                    <td>10X2X15</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Chloramphenicol Capsules IP250mg</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Chloramphenicol Capsules IP500mg</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Cyproheptadine Hcl 2mg and Yeast 100mg capsules</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Diacerein Capsules IP 50mg</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Doxycycline 100MG and Lactic Acid Bacillus</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Esomeprazole 40MG ,Domperidone 30MG</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Ferrous fumarate with multivitamin capsules</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Fluxetine Hydrochloride 20MG CAPSULES</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Itraconazone B.P. 100mg</td>
                    <td>10X1X4</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Itraconazone B.P.200mg</td>
                    <td>10X1X4</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>Lycopene 10 % 2000 mcg+Multivitamin</td>
                    <td>15X1X10</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>
                      Mecobalamin 1500mcg Folic acid 5mg, Alpha lipoic acid
                      100mg and Vitamin B6 3mg capsules
                    </td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>Multivitamin capsules</td>
                    <td>5X2X15</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>Multivitamin Capsules with Protein</td>
                    <td>5X2X15</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>Multivitamin with lactic acid bacillus capsules</td>
                    <td>5X2X15</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>Omeprazole 20 mg</td>
                    <td>20X15</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>Omeprazole 20 mg +Domperidone 10mg </td>
                    <td>20X15</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>Omeprazole EC 20MGand Domperidone30MG SR Capsules</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td>Pantoprazole 40mg+Domperidone 30mg(SR)</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td>Pantoprazole 40mg+itopride 1500mg (SR)</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>Pantoprazole 40 mg+ levosulpride 75mg (SR)</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td>
                      Phenylephrine 20mg & Chlorpheniramine (SR) 8mg Capsules{" "}
                    </td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>Piroxicam Capsules I.P 20mg</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td>Pregabalin 75 mg+Methylcobalamin 750mcg</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td>Pregabalin 75 mcg capsules I.P</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>
                      Rabeprazole Sodium EC20MG and Aceclofenac 200MG SR
                      Capsules
                    </td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>31</td>
                    <td>Rabeprazole Sodium 20 mg+ levosulpride 75mg (SR)</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>Rabeprazole Sodium 20 mg+Domperidone 30mg (SR)</td>
                    <td>10X10</td>
                  </tr>
                  <tr>
                    <td>33</td>
                    <td>Rabeprazole Sodium 20 mg+itopride 1500mg (SR)</td>
                    <td>10X10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Capsule;
