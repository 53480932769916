import './css/drypowder.css';
import Footer from "./Footer";
import Navbar from "./Navbar";
const DryPowder =()=>{
    return (
        <>
  <section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">DRY POWDER INJECTIONS</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" className="contact-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h3 className="my-background">Product List</h3>
          </div>
          <table className="table table-bordered" id="my_table">
            <thead>
              <tr>
                <th scope="col" style={{width:"8%"}}>S.No</th>
                <th scope="col" style={{width:"70%"}}>Composition</th>
                <th scope="col">Packing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Cefazolin for Injection 500 mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Cefazolin for Injection 1000gm</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Cefepime and Tazobactam for Injection 1gm + 125mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Cefepime and Tazobactam for Injection 500mg + 62.5mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Cefepime Injection 250mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Cefepime Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Cefepime Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Cefepime Injection 2000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Cefoperazone & Sulbactam for Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Cefoperazone & Sulbactam for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Cefoperazone & Sulbactam for Injection 1500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Cefoperazone & Sulbactam for Injection 2000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Cefoperazone for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Cefotaxime for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Cefotaxime for Injection 2000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Ceftazidime for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Ceftriaxone & Sulbactam for Injection 750mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Ceftraixone Injection	1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>19</td>
                <td>Cefuroxime for Injection 750mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>20</td>
                <td>Cefuroxime for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>21</td>
                <td>Cefuroxime for Injection 1500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>22</td>
                <td>Amoxicillin & Potassium Clavulanate Injection 300mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>23</td>
                <td>Amoxicillin & Potassium Clavulanate Injection 600mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>24</td>
                <td>Amoxicillin & Potassium Clavulanate Injection 1200mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>25</td>
                <td>Amoxicillin Sodium for Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>26</td>
                <td>Ampicillin & Sulbactam for Injection 750mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>27</td>
                <td>Ampicillin & Sulbactam for Injection 1500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>28</td>
                <td>Ampicillin & Sulbactam for Injection 750mg</td>
                <td>1X1</td>
              </tr><tr>
                <td>29</td>
                <td>Ampicillin and Cloxacillin for Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>30</td>
                <td>Ampicillin and Cloxacillin for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>31</td>
                <td>Ampicillin Sodium Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>32</td>
                <td>Ampicillin Sodium Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>33</td>
                <td>Cloxacillin Sodium for Injection 500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>34</td>
                <td>Imipenem and Cilastatin for Injection 500mg</td>
                <td>1X1</td>
              </tr>
              
              <tr>
                <td>35</td>
                <td>Imipenem and Cilastatin for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>36</td>
                <td>Meropenem for Injection	500mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>37</td>
                <td>Meropenem for Injection	1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>38</td>
                <td>Piperacillin & Tazobactam for Injection 2.25g</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Piperacillin & Tazobactam for Injection 4.5g</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>40</td>
                <td>Piperacillin for Injection 1000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>41</td>
                <td>Piperacillin for Injection 2000mg</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>42</td>
                <td>Doripenem for Injection	500mg/1000mg</td>
                <td>1X1</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
    <Footer />
        </>
    );
    }
    
    export default DryPowder;
    