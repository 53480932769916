import Footer from "./Footer";
import Navbar from "./Navbar";
const External = () => {
  return (
    <>
      <section id="contact-banner" className="banner">
        <div className="contact-bg-color">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="banner-logo text-center">
                  <h1 className="white">EXTERNAL PREPARATIONS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h3 className="my-background">Product List</h3>
              </div>
              <table className="table table-bordered" id="my_table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "8%" }}>
                      S.No
                    </th>
                    <th scope="col" style={{ width: "70%" }}>
                      Composition
                    </th>
                    <th scope="col">Packing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Adapalene 1.0%w/w & Clindamycin 0.1% GEL</td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Adapalene Gel 0.1% </td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Beclomethasone + Neomycin +Chlorocresol</td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Beclo 0.25w/w + Clotrimazole 1%w/w + Genta 0.2% w/w</td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      Beclomethasone Dipropionate 0.025% and Neomycin Sulphate
                      0.5% Cream
                    </td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      Beclomethasone 0.025%Clotrimazole 1% & Neomycin 0.5%cream
                    </td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Bifonazole Cream IP</td>
                    <td>10/50gm</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      Choline Salicylate+ Lignocaine+ Benzalkonium chloride
                    </td>
                    <td>10gm</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      Ciprofloxacin 0.5% + Fluocinolone Acetinide 0.025% +
                      Clotrimazole 1%
                    </td>
                    <td>10gm</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      Ciprofloxacin Hcl 1.00 Metronidazole Benzoate
                      1.00Terbinafine Hydrochloride1.0 Clobetasol
                      Propionate0.05Methyl paraben (preservative)0.2 Propyl
                      Paraben (preservative) 0.02
                    </td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Clindamycin Phosphate and Zinc Acetate Cream</td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Clindamycin Phosphate Gel USP</td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Clobeta 0.05%w/v+Genta 0.2%w/w+Miccona 2.0%w/w</td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Clobetasol Propionate 0.05% Cream I.P</td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>
                      Clobetasol Propionate 0.05% w/w,Neomycin 0.1 % w/w &
                      Miconazole nitrate 2.0%w/w
                    </td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>
                      Clobetasol propionate, Genta, Tolnaftate,
                      Iodochlorhyroxyquinoline and clotrimazole cream.{" "}
                    </td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>
                      Clobetasol Propionate,0.05% Miconazole Nitrate,2.0% Zinc
                      Sulphate 2.0%with Neomycin 0.5% C
                    </td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>Clotrimazole +Beclomethasone </td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>Clotrimazole 1.0% Cream IP</td>
                    <td>10/15gm</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>Diclofenac 1.16% Gel BP</td>
                    <td>10/15gm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default External;
