import "./css/about.css"
import Footer from "./Footer";
import Navbar from "./Navbar";
const About =()=>{
    return (
        <>
<section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">ABOUT US</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" className="section-padding">
    <div className="container" style={{color:"#325c6a"}}>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <p>PNEU PHARMA (INDIA) IS AN INDIAN PHARMACEUTICAL COMPANY INVOLVED IN MANUFACTURING AND SUPPLY OF WIDE RANGE OF PHARMACEUTICAL PRODUCTS ACROSS THE GLOBE. OUR COMMITMENT IS TO PROVIDE WORLD CLASS QUALITY @ COMPETITIVE PRICES AND EXPAND OUR PRODUCT PORTFOLIO IN DIFFERENT PARTS OF THE WORLD. ALL OUR PRODUCTS ARE MANUFACTURED IN OUR WHO-GMP APPROVED MANUFACTURING FACILITIES IN THE NAME OF NAVKAR LIFESCIENCES.</p>
          <p>OUR COMPANYS WIDE PRODUCT BASKET COMPRISES OF TABLETS, CAPSULES, DRY POWDER INJECTION, LIQUID INJECTION, SOFTGELS, OINTMENT, NEUTRACEUTICALS, AND OPTHALMIC RANGE ETC.WE ARE CURRENTLY EXPORTING TO MONGOLIA , MYANMAR, TURKMENISTAN , UZBEKISTAN , GHANA, NIGERIA, ECUADOR AND WE ARE UNDER REGISTRATIONS IN MANY OTHER COUNTRIES</p>
        </div>
        <div className="col-md-12 col-sm-12" style={{marginTop:"3%"}}>
          <h4 style={{color:"#325b6a"}}>OUR VISION</h4>
          <p>TO SERVE THE PEOPLE ALL OVER THE GLOBE WITH OUR QUALITY AND ECONOMICAL PRODUCTS</p>
        </div>
        <div className="col-md-6 col-sm-6" style={{marginTop:"3%"}}>
          <h4 style={{color:"#325b6a"}}>OUR STRENGTHS</h4>
          <ul className="my-list">
            <li>LARGE CAPACITIES TO PRODUCE BULK QUANTITIES</li>
            <li>COMMITMENT TO EXCELLENCE & QUALITY</li>
            <li>COMMITED AND DEDICATED TEAM OF PROFESSIONALS IN MANUFACTURING</li>
            <li>HIGHLY QUALIFIED REGULATORY TEAM</li>
            <li>COST EFFICIENCY</li>
            <li>EXPERIENCED STAFF FOR SMOOTH OPERATIONS</li>
            <li>FAST AND HASSLE FREE SERVICES TO OUR VALUED CLIENTS</li>

          </ul>
          <h4 style={{color:"#325b6a",marginTop:"5%"}}>ACCREDIATIONS</h4>
          <p>AN ISO 9001-2015 COMPANY AT WHO-GMP CERTIFIED MANUFACTURING PLANT</p>
        </div>
        <div className="col-md-6 col-sm-6" style={{marginTop: "3%"}}>
          <h4 style={{color:"#325b6a"}}>OUR MISSION</h4>
          <ul className="my-list">
            <li>TO PROVIDE HIGH QUALITY INNOVATIVE LIFE SAVING DRUGS AT AFFORDABLE PRICES</li>
            <li>TO BECOME ONE OF THE TOP MANUFACTURER AND EXPORTER OF FINISHED FORMULATIOS</li>
            <li>TO BECOME A RELIABLE SUPPLIER OF PHARMACEUTICAL FORMULATIONS FOR OUR VALUED CUSTOMERS</li>
            <li>TO ENTER INTO MORE REGULATED MARKETS IN NEXT 5 YEARS TO SERVE THE PEOPLE WITH OUR QUALITY PRODUCTS</li>
          </ul>
        </div>
        <div className="col-md-12 col-sm-12" style={{marginTop:"3%"}}>
          <h4 style={{color:"#325b6a"}}>MANUFACTURING FAXCILITIES</h4>
          <p>OUR MANUFACTURING COMPANY HAS ULTRA MODERN FACILITIES FOR MANUFACTURING OF FINISHED FORMULATIONS. THE STATE OF ART OF MANUFACTURING PLANT IS BASED UPON WHO-GMP NORMS AND THE COMPANY HAS ISO 9001-2015 CERTIFICATION FOR ITS QUALITY MANAGEMENT</p>
        </div>
      </div>
    </div>
  </section>
  
    <Footer />
        </>
    );
    }
    
    export default About;
    