import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
const Home =()=>{
    return (
        <>
  <section id="banner" className="banner">
    <div className="bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <div className="row">
                <div className="col-lg-8 vcenter"><h1 className="white">PNEU PHARMA (INDIA)</h1></div>
                <div className="col-lg-2"><img src="img/NAVKAR.jpg" alt="" width="150" height="60" className="img-navkar" /></div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="banner-text text-center">
              <h3 className="white" style={{color:"#fdab00",marginTop: "30px"}}>Healthcare at your desk!!</h3>
              <p className="mt-3" style={{lineHeight: "30px",marginTop: "15px",fontSize:"19px",fontWeight:"normal"}}>Pneu Pharma (India) is a highly progressive pharmaceutical company involved in manufacturing<br /> and marketing of Branded and Generic medicines</p>
              <Link to="/contact" className="btn btn-appoint"><b>Visit Us</b></Link>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" className="section-padding">
    <div className="container" style={{color:"#325c6a"}}>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h2 className="text-center">About Us</h2>
          <hr className="botm-line" />
          <p>PNEU PHARMA (INDIA) IS AN INDIAN PHARMACEUTICAL COMPANY INVOLVED IN MANUFACTURING AND SUPPLY OF WIDE RANGE OF PHARMACEUTICAL PRODUCTS ACROSS THE GLOBE. OUR COMMITMENT IS TO PROVIDE WORLD CLASS QUALITY @ COMPETITIVE PRICES AND EXPAND OUR PRODUCT PORTFOLIO IN DIFFERENT PARTS OF THE WORLD. ALL OUR PRODUCTS ARE MANUFACTURED IN OUR WHO-GMP APPROVED MANUFACTURING FACILITIES IN THE NAME OF NAVKAR LIFESCIENCES.</p>
          <p>OUR COMPANYS WIDE PRODUCT BASKET COMPRISES OF TABLETS, CAPSULES, DRY POWDER INJECTION, LIQUID INJECTION, SOFTGELS, OINTMENT, NEUTRACEUTICALS, AND OPTHALMIC RANGE ETC.</p>
        </div>
        <div className="col-md-6 col-sm-6" style={{marginTop:"3%"}}>
          <h4 style={{color:"#325b6a"}}>OUR STRENGTHS</h4>
          <ul className="my-list">
            <li>LARGE CAPACITIES TO PRODUCE BULK QUANTITIES</li>
            <li>COMMITMENT TO EXCELLENCE & QUALITY</li>
            <li>COMMITED AND DEDICATED TEAM OF PROFESSIONALS IN MANUFACTURING</li>
            <li>HIGHLY QUALIFIED REGULATORY TEAM</li>
            <li>COST EFFICIENCY</li>
            <li>EXPERIENCED STAFF FOR SMOOTH OPERATIONS</li>
            <li>FAST AND HASSLE FREE SERVICES TO OUR VALUED CLIENTS</li>

          </ul>
          <h4 style={{color:"#325b6a",marginTop:"5%"}}>ACCREDIATIONS</h4>
          <p>AN ISO 9001-2015 COMPANY AT WHO-GMP CERTIFIED MANUFACTURING PLANT</p>
        </div>
        <div className="col-md-6 col-sm-6" style={{marginTop:"15px"}}>
          <h4 style={{color:"#325b6a"}}>MISSION</h4>
          <ul className="my-list">
            <li>TO PROVIDE HIGH QUALITY INNOVATIVE LIFE SAVING DRUGS AT AFFORDABLE PRICES</li>
            <li>TO BECOME ONE OF THE TOP MANUFACTURER AND EXPORTER OF FINISHED FORMULATIOS</li>
            <li>TO BECOME A RELIABLE SUPPLIER OF PHARMACEUTICAL FORMULATIONS FOR OUR VALUED CUSTOMERS</li>
          </ul>
        </div>
        <div className="col-md-12" style={{marginTop:3}}>
          <h4 style={{color:"#325b6a"}}>MANUFACTURING FACILITIES</h4>
          <p>OUR MANUFACTURING COMPANY HAS ULTRA MODERN FACILITIES FOR MANUFACTURING OF FINISHED FORMULATIONS. THE STATE OF ART OF MANUFACTURING PLANT IS BASED UPON WHO-GMP NORMS AND THE COMPANY HAS ISO 9001-2015 CERTIFICATION FOR ITS QUALITY MANAGEMENT</p>
        </div>
      </div>
    </div>
  </section>




  <section id="service" className="section-padding" style={{backgroundColor: "#fdab00"}}>
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 my-services">
          <h2 className="text-center" style={{color: "#325b6a",marginBottom: "3%"}}>Our <span style={{color:"#fff"}}> Services</span></h2>
          <div className="more-features-box-text-icon"> <i className="fa fa-angle-right" aria-hidden="true"></i> </div>
          <h4 style={{color: "#325b6a",paddingLeft:5}}>Contract Manufacturing</h4>
          <p style={{paddingLeft:"5%"}}>PNEU PHARMA (INDIA) provides contract manufacturing services for tablets, capsules, ointments, suspensions, gels, injections (liquid & dry), softgels.</p>
        </div>

        <div className="col-md-12 col-sm-12 my-services" style={{marginTop: 3}}>
          <div className="more-features-box-text-icon"> <i className="fa fa-angle-right" aria-hidden="true"></i> </div>
          <h4 style={{color:"#325b6a",paddingLeft:5}}>With us you will have the following advantages</h4>
          <ul className="my-list2">
            <li>State-of-the art facilities which adhere to specifications of WHO GMP approved facilities.</li>
            <li>Qualified and experienced technical team in the areas of manufacturing, quality control, quality assurance, and development for all above range of products.</li>
            <li>Reliable supply of quality products</li>
            <li>Manufacturing on neutral code.</li>
          </ul>
          <p style={{fontStyle: "italic", fontWeight:"bold",color:"#325b6a",marginTop:"3"}}>TODAY PNEU PHARMA (INDIA) IS A MANUFACTURER, DISTRIBUTOR AND EXPORTER WITH THE REPUTATION AS A RELIABLE SUPPLIEROF HEALTHCARE PRODUCTS.</p>
        </div>

      </div>
    </div>
  </section>




  <section id="products" className="section-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center font-weight-bolder">Our <span className="text-warning">Products</span> </h2>
          <hr className="botm-line" />
        </div>
        <div className="col-md-3 col-sm-3 col-xs-12">
          <div className="thumbnail thumbnail2">
            <img src="products/1.jpg" alt="..." className="team-img" />
            <div className="caption">
              <ul className="list-inline text-center">
                <li><Link to="/gallery">View All</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-12">
          <div className="thumbnail thumbnail2">
            <img src="products/2.jpg" className="team-img" />
            <div className="caption">
              <ul className="list-inline text-center">
                <li><Link to="/gallery">View All</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-12">
          <div className="thumbnail thumbnail2">
            <img src="products/15.jpg" className="team-img" />
            <div className="caption">
              <ul className="list-inline text-center">
                <li><Link to="/gallery">View All</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-12">
          <div className="thumbnail thumbnail2">
            <img src="products/8.jpg" alt="..." className="team-img" />
            <div className="caption">
              <ul className="list-inline text-center">
                <li><Link to="/gallery">View All</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section id="contact" className="section-padding" style={{backgroundColor: "#f1f1f1"}}>
    <div className="container" style={{color:"#325c6a",marginBottom:"50px"}}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center">Contact us</h2>
          <hr className="botm-line" />
        </div>
        <div className="col-md-4 col-sm-4">
          <h3>Corporate Office</h3>
          <div className="space"></div>
          <h4>PNEU PHARMA (INDIA)</h4>
          <p><i className="my-icons fa fa-map-marker fa-fw pull-left fa-2x"></i> BG-6/314,Ground Floor Paschim Vihar New Delhi -110063</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-envelope-o fa-fw pull-left fa-2x"></i>exportspneupharma@gmail.com, directorpneupharma@gmail.com</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-phone fa-fw pull-left fa-2x"></i>011-35637260</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-mobile fa-fw pull-left fa-2x"></i>+91-9953547678, +91-9582400065, +91-9716677756</p>
        </div>
        <div className="col-md-8 col-sm-8 marb20">
          <div className="contact-info">
            <h3 className="cnt-ttl">Having Any Query!</h3>
            <div className="space"></div>
            <div id="sendmessage">Your message has been sent. Thank you!</div>
            <div id="errormessage"></div>
            <form action="" method="post" role="form" className="contactForm">
              <div className="form-group">
                <input type="text" name="name" className="form-control br-radius-zero" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <input type="email" className="form-control br-radius-zero" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control br-radius-zero" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <textarea className="form-control br-radius-zero" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                <div className="validation"></div>
              </div>

              <div className="form-action text-center">
                <button type="submit" className="btn btn-form">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12" style={{marginTop: "5"}} title="www.pneupharma.com">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.80620117368622!2d77.10617558380562!3d28.662736137078042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDM5JzQ1LjkiTiA3N8KwMDYnMjIuNyJF!5e0!3m2!1sen!2sin!4v1617546094897!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </section>
  
    <Footer />
        </>
    );
    }
    
    export default Home;
    