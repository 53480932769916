import './css/liquidinjectables.css';
import Footer from "./Footer";
import Navbar from "./Navbar";
const LiquidInjectables =()=>{
    return (
        <>
<section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">LIQUID INJECTABLES</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" className="contact-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h3 className="my-background">Product List</h3>
          </div>
          <table className="table table-bordered" id="my_table">
            <thead>
              <tr>
                <th scope="col" style={{width: "8%"}}>S.No</th>
                <th scope="col" style={{width: "70%"}}>Composition</th>
                <th scope="col">Packing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Adrenaline Injection 1mg/ml, in 1ml ampule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Amikacin Sulfate Injection 100mg/2 ml</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Amikacin Sulfate Injection 250mg/2ml</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Amikacin Sulfate Injection 500 mg/2ml</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Aminophylline Injection 25 mg/ml, 10 ml ampoule</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Artemether Injection 40 mg/ml 1ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Artemether Injection 80mg/ml, 1ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Cefepime Injection 2000mg</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Arteether Injection 150mg/2ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Ascorbic Acid Injection (Vitamin C) 100 mg/ml, 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Atracurium Besylate Injection 10 mg/ml, 2.5ml ampule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Atropine Sulphate Injection 0.6mg/ml, 1ml ampule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Atropine Sulphate Injection 0.6mg/ml, 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Atropine Sulphate Injection 0.6mg/ml, 30ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Bupivacaine Hydrochloride In Dextrose Injection 5mg/ml, 4ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Bupivacaine Hydrochloride In Dextrose Injection 80mg/ml</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Bupivacaine Injection 0.5%, 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Calcitonin Injection 50 Iu/ml 50 Iu, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>19</td>
                <td>Chloroquine Phosphate Injection 40 mg/ml, 2ml & 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>20</td>
                <td>Chloroquine Phosphate Injection 40 mg/ml,30ml ampoule</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>21</td>
                <td>Chloroquine Phosphate Injection 64.5 mg/ml, 2ml & 5ml ampule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>22</td>
                <td>Citicoline Injection 125 mg/ml 125 mg/ml, 4ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>23</td>
                <td>Citicoline Injection 250 mg/ml 250 mg/ml, 4ml ampule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>24</td>
                <td>Clindamycin Injection 150mg/ml, 2ml and 4ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>25</td>
                <td>Dexamethasone Sodium Phosphate Injection 4 mg/ml, 1ml & 2ml ampoule</td>
                <td>1X10</td>
              </tr>
              <tr>
                <td>26</td>
                <td>Diclofenac Sodium Injection 25 mg/ml,3ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>27</td>
                <td>Etamsylate Injection 125 mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>28</td>
                <td>Gentamicin Injection 40mg/ml,2ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>29</td>
                <td>Glycopyrrolate Injection 0.2mg/ml, 1ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>30</td>
                <td>Heparin Injection 25000 IU 5000IU/ML, 5ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>31</td>
                <td>Heparin Injection 5000 IU 1000IU/ML, 5ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>32</td>
                <td>Hyoscine Butylbromide Injection 10 mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>33</td>
                <td>Hyoscine Butylbromide Injection 20 mg/ml, 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>34</td>
                <td>Iron Sucrose Injection 20 mg/ml, 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>35</td>
                <td>Lincomycin Injection BP 300mg/ml, 1ml & 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>36</td>
                <td>Meloxicam Injection 15 mg, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>37</td>
                <td>Methylergometrine Injection 0.2 mg/ml, 1ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>38</td>
                <td>Metoclopramide Hydrochloride Injection IP 5 mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Metoclopramide Hydrochloride Injection IP 5 mg/ml, 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>40</td>
                <td>Neostigmine Methylsulphate Injection 0.5mg/ml, 1ml & 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>41</td>
                <td>Ondansetron Injection 2mg/ml, 2ml & 4ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>42</td>
                <td>Ondansetron Injection 2mg/ml, 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>43</td>
                <td>Oxytetracycline Injection 50mg/ml, 30ml multidose vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>44</td>
                <td>Paracetamol Injection 150mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>45</td>
                <td>Paracetamol Injection 150mg/ml, 10ml multidos vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>46</td>
                <td>Phytomenadione Injection 10 mg/ml, 1ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>47</td>
                <td>Promethazine Injection 25 mg/ml, 1ml ampoule</td>
                <td>1X10</td>
              </tr>
              <tr>
                <td>48</td>
                <td>Quinine Dihydrochloride Injection 300mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>49</td>
                <td>Ranitidine Hydrochloride Injection IP 25mg/ml, 2ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>50</td>
                <td>Sterile Water for Injections 2ml, 3ml,5ml,10ml and 20ml ampoule</td>
                <td>1X50</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Succinylcholine Chloride Injection IP 50 mg, 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>52</td>
                <td>Suxamethonium Chloride Injection BP 50 mg/ml, 2ml,5ml & 10ml vial</td>
                <td>1X1</td>
              </tr>
              <tr>
                <td>53</td>
                <td>Tramadol Hydrochloride Injection 50mg/ml, 1ml & 2ml ampoule</td>
                <td>1X10</td>
              </tr>
              <tr>
                <td>54</td>
                <td>Tranexamic Acid Injection 100 mg/ml, 5ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>55</td>
                <td>Vitamin B-Complex Injection 3ml ampoule</td>
                <td>1X5</td>
              </tr>
              <tr>
                <td>56</td>
                <td>α - β Arteether Injection 75mg/ml, 1ml, 2ml & 3ml ampoule</td>
                <td>1X5</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>  
    <Footer />
        </>
    );
    }
    
    export default LiquidInjectables;
    