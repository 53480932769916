import { useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar =()=>{
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
return (
<>
<nav className="navbar navbar-default navbar-fixed-top">
    <div className="container">
        <div className="col-md-12">
            <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                {/* <span style={{color: "#fff",fontSize:"20px",marginTop: "50px !important"}}>
                  <Link style={{textDecoration:"none",color:"#fff",fontWeight:"24px"}} to="/">PNEU PHARMA (INDIA)</Link></span> */}
            </div>
            <div className="collapse navbar-collapse navbar-right" id="myNavbar">
                <ul className="nav navbar-nav">
                    <li className={window.location.pathname==='/#/'?'active':''}><Link to="/">Home</Link></li>
                    <li className={window.location.pathname==='#/about'?'active':''}><Link to="/about">About</Link></li>
                    <li className={window.location.pathname==='/our-services'?'active':''}><Link to="/our-services">Services</Link></li>
                    <li className="dropdown">
                        <Link to="#" data-toggle="dropdown" >Gallery <b className="caret"></b></Link>
                        <ul className="dropdown-menu" style={{marginTop: "16px",backgroundColor: "rgba(12, 184, 182, 0.21)"}}>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/gallery">TOP SELLING PRODUCTS</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/manufacturing-facility">MANUFACTURING FACILITY</Link></li>
                        </ul>
                    </li>
                    <li className="dropdown">
                        <Link to="#" data-toggle="dropdown" >Our Products <b className="caret"></b></Link>
                        <ul className="dropdown-menu" style={{marginTop: "16px",backgroundColor: "rgba(12, 184, 182, 0.21)"}}>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/dry-powder">DRY POWDER INJECTIONS</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/liquid-injectables">LIQUID FOR INJECTIONS</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/tablets">TABLETS</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/capsules">CAPSULES</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/paediatric">PAEDIATRIC DROPS</Link></li>
                            <li><Link className="text-white" style={{color:"#fff",fontWeight:"500"}} to="/external-preparation">EXTERNAL PREPARATIONS</Link></li>
                        </ul>
                    </li>
                    <li className={window.location.pathname==='/contact'?'active':''}><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </div>
    </div>
</nav>
</>
);
}

export default Navbar;
