import "./css/services.css"
import Footer from "./Footer";
import Navbar from "./Navbar";
const Services =()=>{
    return (
        <>
<section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">OUR SERVICES</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="service" className="section-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 my-services">
          <div className="more-features-box-text-icon"> <i className="fa fa-angle-right" aria-hidden="true"></i> </div>
          <h4 style={{color: "#0cb8b6",paddingLeft:"5%"}}>Contract Manufacturing</h4>
          <p style={{paddingLeft:"5%",color:"#325b6a",fontWeight:"700"}}>PNEU PHARMA (INDIA) provides contract manufacturing services for tablets, capsules, ointments, suspensions, gels, injections (liquid & dry), softgels.</p>
        </div>

        <div className="col-md-12 col-sm-12 my-services" style={{marginTop: "3%"}}>
          <div className="more-features-box-text-icon"> <i className="fa fa-angle-right" aria-hidden="true"></i> </div>
          <h4 style={{color:"#0cb8b6",paddingLeft:"5%"}}>With us you will have the following advantages</h4>
          <ul className="my-list2">
            <li style={{color: "#325c6a",fontWeight:"700"}}>State-of-the art facilities which adhere to specifications of WHO GMP approved facilities.</li>
            <li style={{color: "#325c6a",fontWeight:"700"}}>Qualified and experienced technical team in the areas of manufacturing, quality control, quality assurance, and development for all above range of products.</li>
            <li style={{color: "#325c6a",fontWeight:"700"}}>Reliable supply of quality products</li>
            <li style={{color: "#325c6a",fontWeight:"700"}}>Manufacturing on neutral code.</li>
          </ul>
          <p style={{fontStyle:"italic", fontWeight:"bold",color:"#325b6a",marginTop:"3%"}}>TODAY PNEU PHARMA (INDIA) IS A MANUFACTURER, DISTRIBUTOR AND EXPORTER WITH THE REPUTATION AS A RELIABLE SUPPLIEROF HEALTHCARE PRODUCTS.</p>
        </div>

      </div>
    </div>
  </section>
  
    <Footer />
        </>
    );
    }
    
    export default Services;
    