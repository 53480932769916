import Footer from "./Footer";
import Navbar from "./Navbar";
const Contact =()=>{
    return (
        <>
<section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">CONTACT US</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" className="section-padding p-0">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-4">
          <h3>Corporate Office</h3>
          <div className="space"></div>
          <h4>PNEU PHARMA (INDIA)</h4>
          <p><i className="my-icons fa fa-map-marker fa-fw pull-left fa-2x"></i> BG-6/314,Ground Floor Paschim Vihar New Delhi -110063</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-envelope-o fa-fw pull-left fa-2x"></i>exportspneupharma2@gmail.com, directorpneupharma@gmail.com</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-phone fa-fw pull-left fa-2x"></i>011-35637260</p>
          <div className="space"></div>
          <p><i className="my-icons fa fa-mobile fa-fw pull-left fa-2x"></i>+91-9953547678, +91-9582400065, +91-9716677756</p>
        </div>
        <div className="col-md-8 col-sm-8 marb20">
          <div className="contact-info">
            <h3 className="cnt-ttl">Having Any Query!</h3>
            <div className="space"></div>
            <div id="sendmessage">Your message has been sent. Thank you!</div>
            <div id="errormessage"></div>
            <form action="" method="post" role="form" className="contactForm">
              <div className="form-group">
                <input type="text" name="name" className="form-control br-radius-zero" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <input type="email" className="form-control br-radius-zero" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control br-radius-zero" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div className="validation"></div>
              </div>
              <div className="form-group">
                <textarea className="form-control br-radius-zero" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                <div className="validation"></div>
              </div>

              <div className="form-action text-center">
                <button type="submit" className="btn btn-form">Send Message</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12" style={{marginTop: "5%"}} title="www.pneupharma.com">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.80620117368622!2d77.10617558380562!3d28.662736137078042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDM5JzQ1LjkiTiA3N8KwMDYnMjIuNyJF!5e0!3m2!1sen!2sin!4v1617546094897!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </section>
  
    <Footer />
        </>
    );
    }
    
    export default Contact;
    