import Footer from "./Footer";
import Navbar from "./Navbar";
const AllProducts = () => {
  const getImages = () => {
    let content = [];
    for (let i = 1; i <= 42; i++) {
      if (i != null) {
        content.push(
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="thumbnail">
              <img
                src={"products/" + i + ".jpg"}
                alt="..."
                className="team-img"
                width="auto"
              />
            </div>
          </div>
        );
      }
    }
    return content;
  };

  return (
    <>
      <section id="contact-banner" className="banner">
        <div className="contact-bg-color">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="banner-logo text-center">
                  <h1 className="white">TOP SELLING PRODUCTS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-padding">
        <div className="container">
          <div className="row">
            {getImages()}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AllProducts;
