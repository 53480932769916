import { Link } from "react-router-dom";

const Footer =()=>{
return (
<>
<footer id="footer">
  <div className="top-footer">
    <div className="container">
      <div className="row">
        
        <div className="col-md-4 col-sm-4 marb20">
          <div className="ftr-tle">
            <h4 className="white no-padding">QUICK LINKS</h4>
          </div>
          <div className="info-sec">
            <ul className="quick-info">
              <li><Link className="marg" to="/">HOME</Link></li>
              <li><Link className="marg" to="/about">ABOUT</Link></li>
              <li><Link className="marg" to="/our-services">SERVICES</Link></li>
              <li><Link className="marg" to="/contact">CONTACT</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 marb20">
          <div className="ftr-tle">
            <h4 className="white no-padding">OUR PRODUCTS</h4>
          </div>
          <div className="info-sec">
            <ul className="quick-info">
              <li><Link className="marg" to="/dry-powder">DRY POWDER INJECTIONS</Link></li>
              <li><Link className="marg" to="/liquid-injectables">LIQUID FOR INJECTIONS</Link></li>
              <li><Link className="marg" to="/tablets">TABLETS</Link></li>
              <li><Link className="marg" to="/capsules">CAPSULES</Link></li>
              <li><Link className="marg" to="/paediatric">PAEDIATRIC DROPS</Link></li>
              <li><Link className="marg" to="/external-preparation">EXTERNAL PREPARATIONS</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 marb20">
          <div className="ftr-tle">
            <h4 className="white no-padding">Follow us</h4>
          </div>
          <div className="info-sec">
            <ul className="social-icon">
              <li className="bglight-blue"><i className="fa fa-facebook"></i></li>
              <li className="bgred"><i className="fa fa-google-plus"></i></li>
              <li className="bgdark-blue"><i className="fa fa-linkedin"></i></li>
              <li className="bglight-blue"><i className="fa fa-twitter"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-line">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          © Copyright Pneu Pharma Pvt. Ltd. All Rights Reserved
          <div className="credits">
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
</>
);
}

export default Footer;
