import './css/tablet.css';
import Footer from "./Footer";
import Navbar from "./Navbar";
const Tablet =()=>{
    return (
        <>
<section id="contact-banner" className="banner">
    <div className="contact-bg-color">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <div className="banner-logo text-center">
              <h1 className="white">TABLETS</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" className="contact-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h3 className="my-background">Product List</h3>
          </div>
          <table className="table table-bordered" id="my_table">
            <thead>
              <tr>
                <th scope="col" style={{width: "8%"}}>S.No</th>
                <th scope="col" style={{width: "70%"}}>Composition</th>
                <th scope="col">Packing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Aceclo 100mg+Chlorzoxa 250mg+Paracetamol 325 mg</td>
                <td>10X2X10</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Aceclo 100mg+Serratio 10mg+Paracetamol 325 mg</td>
                <td>20X10</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Aceclo 100mg+Serratio 15mg+Paracetamol 325 mg</td>
                <td>20X10</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Aceclofenac 100mg and Serratiopeptidase 10mg Tablets</td>
                <td>10x10</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Aceclofenac 100mg and Thiocolchicoside 4mg tablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Aceclofenac 100mg and Thiocolchicoside 8mg tablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Aceclofenac Tablets IP 100mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Aceclofenac, 100mg Paracetamol 325 mg & Thiocolchicoside 4mgTablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Aceclofenac100 mg +Paracetamol 325 mg</td>
                <td>20X10</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Albendazole 400 mg</td>
                <td>50X1</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Albendazole 400MG and Ivermectin 6 MG tablets</td>
                <td>50X1</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Alfacalcidol 0.25mcg, Calcium 625mg & Betacarotene 2500 I.U. Tablets</td>
                <td>5x2x15</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Amlodipine 5mg and Atenolol 50mg Tablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Amlodipine Tablets IP 5mg</td>
                <td>10x10</td>
              </tr>
              
              <tr>
                <td>15</td>
                <td>Atorvastatin 10 mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Atorvastatin 20 mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Atorvastatin 40 mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Azithromycin 250 mg</td>
                <td>20X1X6</td>
              </tr>
              <tr>
                <td>19</td>
                <td>Azithromycin 500 mg</td>
                <td>20X1X3</td>
              </tr>
              <tr>
                <td>20</td>
                <td>Betahistidine 16mg</td>
                <td>10x10</td>
              </tr>
              <tr>
                <td>21</td>
                <td>Betahistidine 8mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>22</td>
                <td>Betahistine 24mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>23</td>
                <td>Calcium 1000mg+Vitamin D3 250 I.U.+Magnesium Hydroxide 100mg+zinc sulphate 4mg</td>
                <td>5x2x15</td>
              </tr>
              <tr>
                <td>24</td>
                <td>Calcium 1000mg+Vitamin D3 250 I.U.+Magnesium Hydroxide 40mg+l-zinc sulphate 66mg</td>
                <td>5X2X15</td>
              </tr>
              <tr>
                <td>25</td>
                <td>Calcium Carbonate1250 mg+Calcium 500mg+Vit D3 250I.U.</td>
                <td>5X2X15</td>
              </tr>
              <tr>
                <td>26</td>
                <td>Calcium Citrate 1000mg+Vitamin D3 250 I.U.+Magnesium+100mg+Zinc+7.5mg,Mecobalamin 500mcg+Folic acid 5mg+ & Pyridoxine Hcl 10mg Tablets</td>
                <td>20X15</td>
              </tr>
              <tr>
                <td>27</td>
                <td>Calcium citrate,Vitamin D3,Zinc Sulphate and magnesium hydroxide tablets</td>
                <td>5x2x15</td>
              </tr>
              <tr>
                <td>28</td>
                <td>Cetirizine Hydrochloride 10mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>29</td>
                <td>Ciprofloxacin 250 mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>30</td>
                <td>Ciprofloxacin 500 mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>31</td>
                <td>Ciprofloxacin 500mg and Tinidazole 600mg Tablets</td>
                <td>10X2X10</td>
              </tr>
              <tr>
                <td>32</td>
                <td>Citicoline 500MG and Piracetam 400MG Tablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>33</td>
                <td>Citicoline Tablets I.P 500mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>34</td>
                <td>Controlled Release Tablet of Sodium Valporate 134mg and Valporic Acid 58mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>35</td>
                <td>Deflazacort 30mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>36</td>
                <td>Deflazacort 6mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>37</td>
                <td>Deflazacort 2mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>38</td>
                <td>Dextro 10mg+ Phenyl 5mg+ Cpm2mg</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Diacerein, Methyl sulphonyl methane & Glucosamine Sulphate Potassium Chloride Tablets</td>
                <td>10X10</td>
              </tr>
              <tr>
                <td>40</td>
                <td>Diclo 50mg+Serratio 10mg+PCM 325mg</td>
                <td>10X10</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>  
    <Footer />
        </>
    );
    }
    
    export default Tablet;
    