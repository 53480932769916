import { HashRouter, Routes, Route } from "react-router-dom";

import About from "../pages/About";
import Capsule from "../pages/Capsule";
import Contact from "../pages/Contact";
import DryPowder from "../pages/DryPowder";
import Home from "../pages/Home";
import LiquidInjectables from "../pages/LiquidInjectables";
import Services from "../pages/Services";
import Tablet from "../pages/Tablet";
import Paediatric from "../pages/Paediatric";
import External from "../pages/External";
import AllProducts from "../pages/AllProducts";
import ManufacturingFacility from "../pages/ManufacturingFacilites";

const RouteApp = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="our-services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route path="dry-powder" element={<DryPowder />} />
        <Route path="liquid-injectables" element={<LiquidInjectables />} />
        <Route path="tablets" element={<Tablet />} />
        <Route path="capsules" element={<Capsule />} />
        <Route path="paediatric" element={<Paediatric />} />
        <Route path="external-preparation" element={<External />} />
        <Route path="gallery" element={<AllProducts />} />
        <Route path="manufacturing-facility" element={<ManufacturingFacility />} />
        
      </Routes>
    </HashRouter>
  );
};

export default RouteApp;
