import "./css/paediatric.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Paediatric = () => {
  return (
    <>
      <section id="contact-banner" className="banner">
        <div className="contact-bg-color">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="banner-logo text-center">
                  <h1 className="white">PAEDIATRIC DROPS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h3 className="my-background">Product List</h3>
              </div>
              <table className="table table-bordered" id="my_table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "8%" }}>
                      S.No
                    </th>
                    <th scope="col" style={{ width: "70%" }}>
                      Composition
                    </th>
                    <th scope="col">Packing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Paracetamol 100 mg/1ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Albendazole 200 mg/5ml</td>
                    <td>10ml</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Albendazole 200 mg/5ml +Ivermactin</td>
                    <td>10ml</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Azithromycin 200mg/5ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Azithromycin 100mg/5ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Cyproheptadine HCL 2 mg+Tricholine 55mg/ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      Diastase (1:2000)20mg+Cinnamon Oil 200mcg+Craway Oil
                      400mcg+Cardamon Oil 400mcg /ml
                    </td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Ondansetron 2 mg/ml</td>
                    <td>30ml</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>PCM 125mg+CPM 1mg+Phenylephrine 2.5mg/ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      PCM 125mg+CPM 1mg+Phenylephrine 2.5mg+Bromhexine 2mg /ml
                    </td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>
                      Levosalbutamol sulphate 0.25mg+ Ambroxol 7.5mg &
                      Guaiphenesin 12.05mg Drops
                    </td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>B-Complex drop</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Domperidone 1mg/5ml</td>
                    <td>30ml</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Dicyclomine HCL 10mg+Simethicone 40mg/ml</td>
                    <td>15ml</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>
                      Alpha -Amylase 20mg+Papain 10mg+Dill Oil 2mg+Anise oil
                      2mg+Caraway Oil 2mg/ml
                    </td>
                    <td>15ml</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Paediatric;
